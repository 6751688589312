import { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Switch, useHistory, useLocation, } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider, useDispatch, useSelector } from 'react-redux';
import _store from '../redux/store';
import useScreen from '../hooks/userScreeen';
import * as actions from '../redux/actions/auth';
import api from '../api';
import Navigator from '../components/Navigator';
import Splash from '../components/Splash';
import { Login } from '../containers/screens/Auth/Login';
import { Signup } from '../containers/screens/Auth/Signup';
import History from '../containers/screens/History';
import User from '../containers/screens/User';
import MyContract from '../containers/screens/MyContract';
import Contract from '../containers/screens/Contract';
import Cskh from '../containers/screens/CSKH/Cskh';
import Vay from '../containers/screens/Vay';
import DetailProfile from '../containers/screens/DetailProfile';
import { Home } from '../containers/screens/Home';
import Notifications from '../containers/screens/Notifications';
import Wallet from '../containers/screens/Wallet';
import Withdraw from '../containers/screens/Withdraw';
import Verify from '../containers/screens/Verify';
import parseJwt from "../ultis/parseJwt";

const history = createBrowserHistory();

function App() {
	return (
		<Provider store={_store}>
			<Router history={history}>
				<Routers />
			</Router>
		</Provider>
	);
}

const Routers = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const localtion = useLocation();
	const { status, accessToken, profile, dataToken } = useSelector((state) => state._auth);
	const [token] = useState(localStorage.getItem('access_token'));
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		if (status) history.push('/');
		else history.push('/auth');
		(async () => {
			try {
				if (dataToken) {
					const { data } = await api.get(`/personal-information/${dataToken.idUser}`);
					dispatch(actions.initialLogin(data.data));
				}
			} catch (err) { }
		})();
	}, [status, token]);

	useEffect(() => {
		const timeout = setTimeout(() => setLoading(false), 1600);
		return () => clearTimeout(timeout);
	}, [status, token]);

	useEffect(() => {
		(async () => {
			try {
				if (dataToken) {
					const { data } = await api.get(`/personal-information/${dataToken.idUser}`);
					dispatch(actions.initialLogin(data.data));
				}
			} catch (err) { }
		})();
	}, [localtion.pathname]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [localtion.pathname]);
	const { width } = useScreen();

	return (
		<div style={{ width: width >= 1080 ? width / 4 : width, margin: 'auto' }}>
			{loading ? (
				<Splash />
			) : (
				<div
					style={{
						paddingBottom: 80,
						width: width >= 1080 ? width / 4 : width,
						margin: 'auto',
					}}
				>
					{!status ? (
						<Switch>
							<Route path="/auth" component={Login} exact />
							<Route path="/auth/signup" component={Signup} exact />
						</Switch>
					) : (
						<>
							<Switch>
								{' '}
								<Route path="/wallet" component={Wallet} exact />
								<Route
									path="/detail-profile"
									component={DetailProfile}
									exact
								/>
								<Route path="/history" component={History} exact />
								<Route path="/me" component={User} exact />
								<Route
									path="/my-contract"
									component={MyContract}
									exact
								/>
								<Route path="/withdraw" component={Withdraw} exact />
								<Route path="/contracts" component={Contract} exact />
								<Route path="/cskh" component={Cskh} exact />
								<Route path="/vay" component={Vay} exact />
								<Route path="/verify" component={Verify} exact />
								<Route path="/" component={Home} exact />
								<Route
									path="/notifications"
									component={Notifications}
									exact
								/>
							</Switch>
						</>
					)}
					{['/', '/wallet', '/services', '/me'].includes(
						localtion.pathname
					) && (
							<div
								style={{
									position: 'fixed',
									zIndex: 1000,
									bottom: 0,
									width: width >= 1080 ? width / 4 : width,
								}}
							>
								<Navigator />
							</div>
						)}
				</div>
			)}
		</div>
	);
};

export default App;
