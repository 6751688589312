import React, { useState, useRef, useEffect } from 'react';
import { Image, Typography, Spin, message } from 'antd';
import axios from 'axios';
import { CameraOutlined } from '@ant-design/icons';

import Compress from 'react-image-file-resizer';
import converter from '../../../ultis/converterBase64ToBinary';
import './verify.scss';
import { BASE_URL } from '../../../ultis/constant';
const Camera = ({ type, onDispatch }) => {
	const [chooseImage, setChooseImage] = useState();
	const [loading, setLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState()
	const [base64IMG, setBase64IMG] = useState()
	useEffect(() => {
		if (chooseImage) {
			onDispatch(chooseImage);
		}
	}, [chooseImage])

	const onChange = (event) => {
		if (event.target && event.target.files[0]) {
			setSelectedFile({
				id: event.target.id,
				file: event.target.files[0]
			})
		}
	}
	useEffect(() => {
		if (selectedFile) getBase64()
		if (base64IMG) onDispatch(base64IMG)
	}, [selectedFile])

	const getBase64 = () => {
		const reader = new FileReader()
		if (selectedFile.file) reader.readAsDataURL(selectedFile.file)
		reader.onload = () => {
			setBase64IMG(reader.result)
			onDispatch(reader.result)
			localStorage.setItem(type, reader.result)
			setChooseImage(reader.result)
		}
	}

	return (
		<div
			className="image-container"
			style={{
				backgroundImage: `url(${chooseImage})`,
			}}
			onClick={() => document.getElementById(`${type}`).click()}
		>
			{loading ? (
				<Spin spinning />
			) : (
				<>
					{!chooseImage && (
						<CameraOutlined
							style={{ fontSize: 30, color: chooseImage ? '#fff' : '#333' }}
						/>
					)}
					<input
						type="file"
						accept="image/*"
						onChange={e => onChange(e)}
						id={`${type}`}
						hidden
					/>

					{type === 'front' && !chooseImage && (
						<Typography.Text
							style={{
								color: chooseImage ? '#fff' : '#333',
								fontWeight: 700,
								fontSize: 16,
							}}
						>
							Mặt trước CMND / CCCD
						</Typography.Text>
					)}
					{type === 'back' && !chooseImage && (
						<Typography.Text
							style={{
								color: chooseImage ? '#fff' : '#333',
								fontWeight: 700,
								fontSize: 16,
							}}
						>
							Mặt sau CMND / CCCD
						</Typography.Text>
					)}
					{type === 'face' && !chooseImage && (
						<Typography.Text
							style={{
								color: chooseImage ? '#fff' : '#333',
								fontWeight: 700,
								fontSize: 16,
							}}
						>
							Ảnh chân dung
						</Typography.Text>
					)}
				</>
			)}
		</div>
	);
};

export default ({ onOk }) => {
	const [front, setFront] = useState(null);
	const [back, setBack] = useState(null);
	const [face, setFace] = useState(null);
	return (
		<div
			initial={{ opacity: 0.3, y: 10 }}
			animate={{ opacity: 1, y: 0 }}
			className="user-img-container"
		>
			<Typography.Text strong style={{ fontSize: 18, padding: 5 }}>
				Chụp ảnh định danh KYC
			</Typography.Text>
			<Camera type="front" onDispatch={(e) => { console.log(e); setFront(e) }} />
			<Camera type="back" onDispatch={(e) => setBack(e)} />
			<Camera type="face" onDispatch={(e) => setFace(e)} />
			<div
				// whileTap={{ opacity: 0.4, scale: 0.97 }}
				className="confirm-div"
				onClick={() => {
					if (front && back && face) onOk({ front, back, face });
					else message.error('Vui lòng cung cấp đủ thông tin');
				}}
			>
				<Typography.Text
					style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
				>
					Tiếp tục
				</Typography.Text>
			</div>
		</div>
	);
};
