import React, { useEffect, useState } from 'react';
import { Button, Carousel, Image, Typography } from 'antd';
import tickAsset from '../../../assets/tick.png';

import { useHistory } from 'react-router-dom';
import Notification from '../../../components/Notification';
import home from '../../../assets/banner.jpg';
import _logo from '../../../assets/logo.png';

import './Home.scss';
import { useSelector } from 'react-redux';
import api from "../../../api";
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';

const arrayText = [
	'Chúc mừng 097***4 đã rút 35.000.000 đ',
	'Chúc mừng 033***8 đã rút 30.000.000 đ',
	'Chúc mừng 087***3 đã rút 45.000.000 đ',
	'Chúc mừng 033***1 đã rút 100.000.000 đ',
	'Chúc mừng 035***5 đã được duyệt hồ sơ',
	'Chúc mừng 033***5 đã rút 80.000.000 đ',
];

export const Home = () => {
	const history = useHistory();
	const { profile, dataToken } = useSelector((state) => state._auth);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [newData, setNewData] = useState()

	useEffect(() => {
		const interval = setInterval(() => {
			if (currentIndex < arrayText.length - 1)
				setCurrentIndex((prev) => prev + 1);
			else setCurrentIndex((prev) => 0);
		}, 2500);

		return () => clearInterval(interval);
	});

	useEffect(() => {
		fetchData()
	}, [newData])

	const fetchData = async () => {
		if (dataToken) {
			const res = await api.get(`/personal-information/${dataToken?.idUser}`);
			console.log('res', res);
		}


	}

	console.log('dataToken', dataToken);

	return (
		<div
			initial={{ opacity: 0.3, y: 10 }}
			animate={{ opacity: 1, y: 0 }}
			className="container"
		>
			<div className="header-content">

				<div>
					<Typography.Text className="text-head">Xin chào </Typography.Text>
					<Typography.Text className="text-head">
						{profile?.kyc?.name || dataToken?.phone}
					</Typography.Text>
				</div>
				<div
					// whileTap={{ scale: 0.9, opacity: 0.7 }}
					onClick={() => history.push('/notifications')}
				>
					<Notification />
				</div>
			</div>

			<div>
				<img src={_logo} width={150} alt="banner" className='imgLogo' />
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					minHeight: 150,
					marginTop: 20,
				}}
			>
				<Image
					preview={false}
					src={home}
					width={'90%'}
					style={{ borderRadius: 10 }}
				/>
			</div>

			<div className="noti-text-container">
				<Typography.Text className="noti-text">
					<p>{arrayText[currentIndex]}</p>
					<p>{arrayText[currentIndex - 1]}</p>
				</Typography.Text>
			</div>

			<Button
				className="choose-btn"
				onClick={() => {
					history.push('/vay');
				}}
			>
				<Typography.Text
					style={{ color: '#fff', fontWeight: 'bold', fontSize: 20 }}
				>
					Đăng ký khoản vay
				</Typography.Text>
			</Button>
			<div style={{ padding: 20 }}>
				<Tab
					title={'Thủ tục vay nhanh chóng, đơn giản'}
					icon={<AlertFilled style={{ fontSize: 20, color: '#ff7323' }} />}
				/>
				<Tab
					title={'Hạn mức vay lên đến 500tr VNĐ'}
					icon={<DashboardFilled style={{ fontSize: 20, color: '#008f50' }} />}
				/>
				<Tab
					title={'Nhận tiền chỉ sau 30 phút làm hồ sơ'}
					icon={<SlidersFilled style={{ fontSize: 20, color: '#006997' }} />}
				/>
			</div>

			<Carousel autoplay autoplaySpeed={2800} className="carousel-container">
				<div>
					<div className="carousel slider2">
						<div className="sl-text-container"></div>
					</div>
				</div>
				<div>
					<div className="carousel slider3">
						<div className="sl-text-container"></div>
					</div>
				</div>
				<div>
					<div className="carousel slider4">
						<div className="sl-text-container"></div>
					</div>
				</div>
			</Carousel>
			<div
				style={{
					paddingTop: 20,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Image src={tickAsset} preview={false} style={{ maxWidth: 100 }} />
				<Typography.Text
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#555',
						textAlign: 'center',
					}}
				>
					&reg; Bản quyền thuộc về công ty cổ phần Dịch vụ Tài chính
					<br /> ANH PHÚC
				</Typography.Text>
			</div>
		</div>
	);
}
const Tab = ({ title, onClick, icon }) => {
	return (
		<div
			// whileTap={{
			// 	scale: 0.96,
			// 	opacity: 0.3,
			// 	border: '1px solid #006B68',
			// }}
			onClick={onClick}
			style={{
				margin: '10px 0px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '7px 15px',
				border: '1px solid #006B68',
				borderRadius: 5,
			}}
		>
			<Typography.Text
				style={{ flex: 1, fontSize: 16, color: '#006B68', fontWeight: 500 }}
			>
				{title}
			</Typography.Text>
			{icon}
		</div>
	);
};
