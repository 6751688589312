import api from '../../api';
import { message } from 'antd';
import {
	LOGIN_SUCCESS,
	LOGOUT,
	EXPRIED,
	CLOSE_EXPRIED,
	SET_PROFILE,
} from '../constants';

import parseJwt from '../../ultis/parseJwt';


export const initialLogin = (data) => async (dispatch) => {
	let dataToken = (localStorage.getItem('access_token')) ? parseJwt(localStorage.getItem('access_token')) : null
	return dispatch({ type: LOGIN_SUCCESS, payload: data, dataToken: dataToken });
};

export const Login = (params) => async (dispatch) => {
	try {
		const { data } = await api.post('/auth/authenticate', params);
		localStorage.setItem('access_token', data.data.accessToken);
		let dataToken = (localStorage.getItem('access_token')) ? parseJwt(localStorage.getItem('access_token')) : null
		return dispatch({ type: LOGIN_SUCCESS, payload: {}, dataToken: dataToken });
	} catch (err) { }
};

export const Logout = () => async (dispatch) => {
	localStorage.removeItem('access_token');
	return dispatch({ type: LOGOUT });
};

// export const setUserProfile = (payload) => async (dispatch) => {
// 	return dispatch({ type: SET_PROFILE, payload });
// };
