import axios from 'axios';
import ReduxStore from '../redux/store';
import * as actions from '../redux/actions/auth';

import { message } from 'antd';
import { BASE_URL } from '../ultis/constant';

const instance = axios.create({
	baseURL: BASE_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=UTF-8',
		'Cache-Control': 'no-cache',
		// 'Access-Control-Allow-Origin': true,
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		'Access-Control-Allow-Credentials': 'true',
		mode: 'no-cors',
	},
});
instance.interceptors.request.use(async (config) => {
	try {
		const token = localStorage.getItem('access_token');
		if (token) config.headers['Authorization'] = `Bearer ${token}`;
	} catch (error) { }
	return config;
});
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		if ([401].includes(error.response.status)) {
			const { dispatch } = ReduxStore;
			dispatch(actions.Logout());
		}
		message.error(error.response.data.message);
		throw error.response.data.message;
	}
);

export default instance;
